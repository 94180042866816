import React, { Component } from 'react';
import './HomePage.css';
import { Grid } from 'semantic-ui-react';
// import validator from 'validator';
import firebase from './Firebase';

import SuggestionIntro from './features/suggestion/SuggestionIntro';
import SuggestionForm from './features/suggestion/SuggestionForm';

class HomePage extends Component {
  state = {
    name: '',
    email: '',
    suggestion: '',
    subscribe: false,
    isEnabled: true,
    error: true
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleError = () => {
    if (
      this.state.name === '' ||
      this.state.email === '' ||
      this.state.suggestion === ''
    ) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }
  };
  handleToggleCheckbox = () => {
    this.setState({ subscribe: !this.state.subscribe });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (
      this.state.name === '' ||
      this.state.email === '' ||
      this.state.suggestion === ''
    ) {
      this.setState({ isEnabled: false, error: true });
      this.props.history.push('/');
    } else {
      const db = firebase.firestore();
      db.collection('suggestions')
        .add({
          name: this.state.name,
          email: this.state.email,
          suggestion: this.state.suggestion,
          subscribe: this.state.subscribe,
          createdAt: Date.now()
        })
        .then(
          this.setState({ name: '', email: '', suggestion: '' }),
          this.props.history.push('/ThankYou')
        )
        .catch(function(error) {
          console.error('Error adding: ', error);
        });
    }
  };

  render() {
    const { name, email, suggestion, error } = this.state;
    const isEnabled = name > 0 && email > 0 && suggestion > 0;
    return (
      <div className="HomePage">
        <img className="people" src="/assets/images/people.png" alt="People" />
        <Grid stackable divided columns={2}>
          <Grid.Row id="Homerow">
            <SuggestionIntro />
            <SuggestionForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handleToggleCheckbox={this.handleToggleCheckbox}
              name={name}
              email={email}
              suggestion={suggestion}
              isEnabled={isEnabled}
              error={error}
            />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default HomePage;
