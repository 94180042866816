import React from 'react';
import { Grid } from 'semantic-ui-react';
import './Suggestion.css';

function Intro() {
  return (
    <div id="suggestion-intro">
      <Grid.Column>
        <div className="left-side">
          <h1>
            <span className="brand">habit</span>creators
          </h1>
          <div className="title-content">
            <h2 className="platform">A platform to make life easier.</h2>
            <p className="question">Creating a community around habits.</p>
            <p className="advice">
              We need your advice. Please tell us your suggestion.
            </p>
            <p>Let's create together.</p>
            <p>What would you like to us to create?</p>
          </div>
        </div>
      </Grid.Column>
    </div>
  );
}

export default Intro;
