import React from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';

import HomePage from '../../HomePage';
import ThankYou from '../../ThankYou';

const App = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Route path="/" exact component={HomePage} />
        <Route path="/thankyou" exact component={ThankYou} />
      </div>
    </BrowserRouter>
  );
};

export default App;
