import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import './Thankyou.css';

const ThankYou = () => {
  return (
    <div class="Thankyou">
      <img className="people" src="/assets/images/people.png" alt="People" />
      <h1>Thank you for your suggestions.</h1>
      <h2>We are working to have the power of habits at your fingertips.</h2>
      <h2>Be on the lookout for our beta app coming soon.</h2>

      <Button as={Link} to="/" content="Back to Home" />
    </div>
  );
};

export default ThankYou;
