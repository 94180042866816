import React from 'react';
import { Grid, Form, Button, Checkbox, Message } from 'semantic-ui-react';
import './Suggestion.css';

class SuggestionForm extends React.Component {
  render() {
    const {
      handleChange,
      handleSubmit,
      handleToggleCheckbox,
      handleError,
      name,
      email,
      suggestion,
      isEnabled,
      error
    } = this.props;
    return (
      <Grid.Column id="suggestion-form">
        <div className="right-side">
          <p>Submit your suggestion below. Thank you!</p>
          <Form onSubmit={handleSubmit} error={handleError} id="form-sugg">
            <Form.Field className="form-group">
              <label htmlFor="name">Name</label>
              <Form.Input
                type="text"
                name="name"
                id="name"
                placeholder="name"
                value={name}
                onChange={handleChange}
                error={handleError}
              />
              {!error && (
                <Message
                  error
                  header="Oops!"
                  content="You need to enter your name"
                />
              )}
            </Form.Field>
            <Form.Field className="form-group">
              <label htmlFor="email">Email</label>
              <Form.Input
                type="email"
                name="email"
                id="email"
                placeholder="email"
                value={email}
                onChange={handleChange}
              />

              <Message
                error
                header="Oops!"
                content="You need to enter your email"
              />
            </Form.Field>
            <Form.Field className="input-group">
              <label htmlFor="suggestion">Suggestions</label>
              <Form.TextArea
                name="suggestion"
                id="suggestion"
                cols="20"
                rows="5"
                placeholder="suggestions"
                value={suggestion}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                toggle
                label="Subscribe to newsletter"
                onClick={handleToggleCheckbox}
              />
            </Form.Field>
            <Button onClick={handleSubmit} disabled={isEnabled}>
              Submit
            </Button>
          </Form>
        </div>
      </Grid.Column>
    );
  }
}

export default SuggestionForm;
