import * as firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyDlfkQeW3y8LOrIw2FKHw2OF7ox7dY-nNU',
  authDomain: 'habitcreators-676a2.firebaseapp.com',
  databaseURL: 'https://habitcreators-676a2.firebaseio.com',
  projectId: 'habitcreators-676a2',
  storageBucket: 'habitcreators-676a2.appspot.com',
  messagingSenderId: '790359655030',
  appId: '1:790359655030:web:a93218f2b0aedfb6'
};

firebase.initializeApp(config);

export default firebase;
